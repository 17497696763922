import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import GallerySlider from '../components/GallerySlider'

const HomeIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <p>
          No posts found
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <div className="text-sm">
      
      <GallerySlider />
      
        {/* <div className="mt-5 p-5 text-sm overflow-hidden bg-zinc-50">
          <h2 className="font-bold text-xl">LATEST ARTICLES</h2>
          <ul className="divide-y divide-gray-200">
            {posts.map(post => {
              const title = post.frontmatter.title || post.fields.slug
              return (
                <li key={post.fields.slug} className="py-4">
                  <Link to={post.fields.slug} itemProp="url" className="post-link">
                    <h2 className="font-semibold text-md">
                        <span itemProp="headline">{title}</span>
                    </h2>
                      <p
                        className="text-gray-700"
                        dangerouslySetInnerHTML={{
                          __html: post.frontmatter.description || post.excerpt,
                        }}
                        itemProp="description"
                      />
                  </Link>
                </li>
              )
            })}
          </ul>
          <Link to="/blog"><button className="w-full mt-3 p-3 text-md bg-black font-semibold text-white  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2">Show All Post</button></Link>
        </div> */}
      </div>
    </Layout>
  )
}

export default HomeIndex

export const Head = () => <Seo title="Home" />

export const pageQuery = graphql`
  {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/content/project/" } } 
      sort: { fields: frontmatter___date, order: DESC }
      limit: 20
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`

