import * as React from "react"
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import amsterdam from '../images/projects/1_amsterdam.jpg'
import gozzer from '../images/projects/2_gozzer.jpg'
import waterst from '../images/projects/3_55waterst.jpg'
import lomavista from '../images/projects/4_lomavista.jpg'
import hermosa from '../images/projects/6_hermosa.jpg'

const GallerySlider = () => {
  return (
    <Splide 
      options={{
        type   : 'fade',
        rewind : true,
        pagination: false,
        autoplay: true,
        pauseOnHover: false,
        pauseOnFocus: false,
        resetProgress: true,
        speed: 400
      }}
    >
      <SplideSlide>
        <img src={amsterdam} alt="Amsterdam" />
      </SplideSlide>
      <SplideSlide>
        <img src={gozzer} alt="Gozzer" />
      </SplideSlide>
      <SplideSlide>
        <img src={waterst} alt="55 Water Street" />
      </SplideSlide>
      <SplideSlide>
        <img src={lomavista} alt="Loma Vista" />
      </SplideSlide>
      <SplideSlide>
        <img src={hermosa} alt="Hermosa" />
      </SplideSlide>
    </Splide>
  );
};

export default GallerySlider;
